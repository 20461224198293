<template>
  <c-box id="DetailClientQuizionaryPage" w="100%">
    <c-text
        color="brand.900"
        font-weight="bold"
        font-size="18px"
        line-height="150%"
    >
      Kuesioner
    </c-text>
    <c-text font-weight="bold" font-size="24px" line-height="150%" color="#111">
      Program Body Goals
    </c-text>

    <c-flex
        w="100%"
        max-w="800px"
        mt="30px"
        justifyContent="space-between"
        alignItems="center"
        v-chakra="{
        '.step': {
          w: '100%',
          h: '8px',
          maxWidth: '156px',
          borderRadius: '20px',
          backgroundColor: 'superLightGray.900',
          marginRight: '12px',
          ':last-child': {
            marginRight: '0',
          },
          '&.done': {
            backgroundColor: 'brand.900',
          },
        },
      }"
    >
      <c-box
          v-for="(_, index) in 7"
          :key="index"
          class="step"
          :class="{ done: index < page }"
      />
    </c-flex>

    <c-text
        v-if="selectedQuizionary"
        mt="20px"
        font-weight="bold"
        font-size="18px"
        line-height="150%"
    >
      {{ selectedQuizionary.title }}
    </c-text>


    <QuizionaryDetail
        v-if="selectedQuizionary && page !== 5"
        :data="selectedQuizionary.data"
        v-chakra
        mt="20px"
    />
    <template v-if="selectedQuizionary && page === 5">
      <Quizionary5Detail
        v-for="item in selectedQuizionary.data.filter((it) => it.value.isDinamis == null)"
        :key="item.value.id"
        :animal-side-dish="item.value.animalSideDish"
        :category="item.value.category"
        :drink="item.value.drink"
        :fruit="item.value.fruit"
        :photo-url="item.value.photoUrl"
        :snack="item.value.snack"
        :staple-food="item.value.stapleFood"
        :vegetable="item.value.vegetable"
        :vegetable-side-dish="item.value.vegetableSideDish"
        v-chakra
        mt="10px"
      />

      <template v-if="selectedQuizionary.data.filter((it) => it.value.isDinamis).length > 0">
        <hr v-chakra mt="30px" />
        <c-box mt="30px">
          <template v-for="item in selectedQuizionary.data.filter((it) => it.value.isDinamis)">
            <QuizionaryDetail
              :key="item.id"
              :data="[item.value]"
            />
          </template>
        </c-box>
      </template>
    </template>

    <c-box d="flex" mt="30px" justify-content="space-between">
      <c-button
          d="flex"
          grid-gap="5px"
          px="20px"
          rounded="full"
          variant-color="brand"
          :is-disabled="page === 1"
          as="router-link"
          :to="{ query: { page: page - 1 } }"
      >
        <c-icon name="chevron-left" size="24px" />
        <c-text>Kembali</c-text>
      </c-button>
      <c-button
          d="flex"
          grid-gap="5px"
          px="20px"
          rounded="full"
          variant-color="brand"
          :is-disabled="page === 7"
          as="router-link"
          :to="{ query: { page: page + 1 } }"
      >
        <c-text>Selanjutnya</c-text>
        <c-icon name="chevron-right" size="24px" />
      </c-button>
    </c-box>
  </c-box>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import QuizionaryDetail from "@/components/quizionary/detail.vue";
import Quizionary5Detail from "@/components/quizionary/detail-kuis-5.vue";
import quizionaryLabelMap from "@/utils/quizionary-label-map.js";
import quizionaryValueMap from "@/utils/quizionary-value-map";
import {sortMealCategory} from "@/utils/sort-meal-category";

export default {
  name: "DetailClientQuizionaryPage",
  components: { QuizionaryDetail, Quizionary5Detail, },
  computed: {
    ...mapState({
      quizData: (state) => state.kuisioner,
    }),
    programId() {
      return this.$route.params.programId;
    },
    page() {
      return +(this.$route.query.page ?? 1);
    },
    selectedQuizionary() {
      return this.quizionary[this.page];
    },
    quizionary() {
      let page5 = [...this.quizData.foodRecords].sort((a, b) => sortMealCategory(a.category, b.category))

      return {
        1: {
          title: "Identitas & Data Umum",
          data: Object.entries(_.omit(this.quizData.generalData, "id")).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          }))
        },
        2: {
          title: "Data Antropometri (Ukuran & Komposisi Tubuh)",
          data: Object.entries(_.omit(this.quizData.anthropometryData, "id")).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
        },
        3: {
          title: "Data Pola & Riwayat Makan",
          data: Object.entries(_.omit(this.quizData.dietAndEatingHistory, "id")).map(
              ([key, value], index) => ({
                order: index,
                label: quizionaryLabelMap[key] ?? key,
                value: quizionaryValueMap[key]?.(value) ?? value,
              })
          ),
        },
        4: {
          title: "Data Aktivitas Harian",
          data: Object.entries(_.omit(this.quizData.dailyActivities, "id")).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
        },
        5: {
          title: "Food Record 24 Jam",
          data: Object.entries(_.omit(page5, "id")).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          }))
        },
        6: {
          title: "Kemampuan Aktivitas Fisik",
          data: Object.entries(_.omit(this.quizData.physicalActivityAbilities, "id")).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          }))
        },
        7: {
          title: "Kondisi Pribadi & Riwayat Medis",
          data: Object.entries(_.omit(this.quizData.medicalHistory, "id", "specialMedicine")).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
        }
      };
    },
  },
  async mounted() {
    let [questions, answers] = await Promise.all([
      this.$store.dispatch("kuisioner/getV2Client", this.programId),
      this.$store.dispatch("kuisioner/getV2ClientAnswer", this.programId),
    ])

    for (let answer of answers.answers) {
      let index = questions.findIndex(it => it.id === answer.questionId);
      if (index >= 0) {
        questions[index].answer = answer.answer
      }
    }

    const categoryMap = {
      general_data: "generalData",
      anthropometry_data: "anthropometryData",
      diet_and_eating_history: "dietAndEatingHistory",
      daily_activities: "dailyActivities",
      food_records: "foodRecords",
      physical_activity_abilities: "physicalActivityAbilities",
      medical_history: "medicalHistory",
    }

    let food = questions.filter(it => it.category === 'food_records')
        .map((item, index) => ({
          id: item.id,
          label: item.question,
          value: item.answer,
          order: index,
          isDinamis: true,
        }))
    this.quizData.foodRecords.push(...food)

    questions = questions.map((it) => ({
      ...it,
      category: categoryMap[it.category],
      // question: `[dynamic] ${it.question}`
    }))

    for (let item of questions) {
      if (item.category !== 'foodRecords') {
        console.log(item)
        // this.$set(this.quizData[item.category], item.question, item.answer)
      }
    }
  },
};
</script>
