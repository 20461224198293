<template>
  <c-flex flex-dir="column">
    <c-text color="brand.900" font-size="21px">{{category | getMenuCategory}}</c-text>
    <template v-if="photoUrl != null">
      <c-link :href="photoUrl" target="_blank" rel="noopener noreferrer">
        <c-image h="250px" w="400px" :src="photoUrl" object-fit="contain" />
      </c-link>
    </template>
    <template v-else-if="snack != null">
      <c-text>Snack</c-text>
      <c-text>{{snack}}</c-text>
    </template>
    <template v-else>
      <c-text>Makanan Pokok atau Sumber Karbohidrat</c-text>
      <c-text>{{stapleFood}}</c-text>
      <c-text>Lauk hewani</c-text>
      <c-text>{{animalSideDish}}</c-text>
      <c-text>Lauk nabati</c-text>
      <c-text>{{vegetableSideDish}}</c-text>
      <c-text>Sayur</c-text>
      <c-text>{{vegetable}}</c-text>
      <c-text>Buah</c-text>
      <c-text>{{fruit}}</c-text>
      <c-text>Minuman</c-text>
      <c-text>{{drink}}</c-text>
    </template>
  </c-flex>
</template>

<script>
import getMenuCategory from "@/utils/get-menu-category";

export default {
  name: "detail-kuis-5",
  props: {
    "category": String,
    "stapleFood": String,
    "animalSideDish": String,
    "vegetableSideDish": String,
    "vegetable": String,
    "fruit": String,
    "drink": String,
    "snack": String,
    "photoUrl": String,
  },
  filters: {
    getMenuCategory(val) {
      return getMenuCategory(val);
    }
  }
}
</script>

