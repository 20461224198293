let page1 = {
  firstName: "Nama Depan",
  middleName: "Nama Tengah",
  lastName: "Nama Belakang",
  gender: "Jenis Kelamin",
  age: "Usia",
  birthDate: "Tanggal Lahir",
  address: "Alamat",
  country: "Negara",
  province: "Provinsi",
  city: "Kota",
  zipCode: "Kode Pos",
  phone: "Nomor Telepon",
  mainTarget: "Apa tujuan utama kamu?",
  expectedDiet: "Tipikal diet yang kamu harapkan",
  hasScales: "Apakah kamu memiliki timbangan berat badan di rumah?",
  currentCondition: "Mana yang paling mendeskripsikan kondisi Klien saat ini?",
}
let page2 = {
  weight: "Berat Badan",
  height: "Tinggi Badan",
  waistSize: "Lingkar Pinggang",
  fat: "Persen Lemak (Body Fat Percentage)",
  muscleMass: "Massa Otot (Muscle Mass)",
  bodyWater: "Persen Cairan Tubuh (Body Water Percentage)",
  visceralFat: "Lemak Visceral (Visceral Fat)",
  boneMass: "Massa Tulang (Bone Mass)",
  basalMetabolicRate: "Kebutuhan Energi Basal atau BMR (Basal Metabolic Rate)",
}
let page3 = {
  foodPreference: "Preferensi Makanan",
  breakfastHabits: "Bagaimana kebiasaan sarapan kamu?",
  breakfastFoodType: "Jenis makanan yang kamu konsumsi saat sarapan?",
  mainFoodConsumption:
    "Biasanya, berapa kali kamu makan berat atau makan utama dalam 1 hari?",
  snackConsumption: "Biasanya, berapa kali kamu makan cemilan dalam 1 hari?",
  fluidConsumption:
    "Berapa rata-rata jumlah konsumsi cairan kamu setiap hari? (Termasuk minuman selain air mineral)",
  favoriteFood: "Makanan apa yang hampir setiap hari kamu konsumsi?",
  dislikeFood: "Makanan apa yang tidak kamu suka?",
  foodConsumptionType:
    "Kamu sukanya cita rasa makanan atau jenis makanan yang seperti apa?",
  expectedBreakfast:
    "Makanan apa saja yang kamu harapkan muncul di menu sarapan?",
  expectedLunchDinner:
    "Makanan apa saja yang kamu harapkan muncul di menu makan siang dan makan malam?",
  foodAllergies:
    "Adakah makanan apa yang menyebabkan kamu alergi, jika ada sebutkan jenis makannya?",
  vegetablesConsumption: "Berapa banyak kamu mengonsumi sayuran dalam sehari?",
  fruitConsumption: "Berapa banyak kamu mengonsumi buah dalam sehari?",
  beveragesComsumption:
    "Berapa banyak kamu mengonsumsi minuman kemasan atau minuman manis dalam sehari?",
  friedSnackConsumption:
    "Berapa banyak kamu mengonsumsi cemilan gorengan (diluar lauk dalam makan utama) dalam sehari?",
  saltySnackConsumption:
    "Berapa sering kamu mengonsumsi makanan ringan asin atau gurih dalam sehari (seperti makanan ringan kemasan, keripik, kacang goreng asin)?",
  sweetSnackConsumption:
    "Berapa sering kamu mengonsumsi cemilan manis dalam sehari (seperti kue-kue manis, brownies, cake, biskuit, coklat, wafer)?",
  personServeFood: "Siapakah yang menyediakan makanan bagi kamu?",
  milkFoodAndDrinkConsumption:
    "Apakah kamu bisa dan terbiasa konsumsi makanan dan minuman yang mengandung susu?",
  lifestyle:
    "Tak kenal maka tak sayang.. Tolong ceritakan dong hal lain tentang pola makan dan gaya hidup kamu yang perlu kami tahu :)",
}
let page4 = {
  activeLevel: "Bagaimana tingkat keaktifan kamu dalam sehari?",
  sportType: "Jenis olahraga yang dilakukan",
  weekdaysTypicalActivity: "Tipikal keaktifan kamu pada hari kerja (weekdays)",
  weekendTypicalActivity:
    "Tipikal keaktifan kamu pada hari libur dan akhir pekan",
  averageSleepHours: "Berapa jam rata-rata durasi tidur per hari",
  bedTime: "Jam berapa biasa kamu tidur?",
}
let page6 = {
  hasDoctorDiagnose:
    "Apakah kamu pernah didiagnosa dokter untuk mengurangi aktifitas fisik kecuali yang direkomendasikan dokter?",
  hasChestPain:
    "Apakah kamu pernah merasa nyeri dada saat melakukan aktifitas fisik?",
  hasLostBalance:
    "Apakah kamu pernah merasa kehilangan keseimbangan karena pusing sampai kehilangan kesadaran/pingsan?",
  hasBoneProblem:
    "Apakah kamu mempunyai masalah tulang atau persendian yang menjadi lebih parah ketika melakukan aktivitas fisik",
  isOnTreatment:
    "Apakah saat ini kamu sedang dalam pengobatan/minum obat untuk tekanan darah tinggi atau penyakit jantung?",
  hasOtherCondition:
    "Selain poin diatas, apakah kamu mempunyai kondisi tertentu yang membatasi untuk beraktifitas fisik?",
}
let page7 = {
  job: "Pekerjaan",
  lastEducation: "Pendidikan Terakhir",
  mentalDisorder: "Apakah kamu memiliki masalah atau gangguan berikut?",
  disease:
    "Adakah saat ini kamu dinyatakan mengidap salah satu kondisi di bawah ini oleh dokter?",
  symptom: "Adakah kamu mengalami keluhan dibawah ini dalam 3 hari terakhir?",
  drugsConsumption:
    "Adakah konsumsi obat-obatan rutin yang perlu kami ketahui? Jika ada, mohon sebutkan obat untuk apa atau merk obatnya",
  currentBehavior: "Adakah kamu termasuk salah satu dibawah ini?",
  supplementConsumption:
    "Adakah jenis multivitamin atau tablet suplementasi yang kamu konsumsi? Jika ada, mohon seburkan jenisnya atau merknya",
  otherDisease:
    "Adakah riwayat penyakit atau kondisi kesehatan lainnya yang perlu kami ketahui?",
  communicationIntensity:
    "Seperti apa intensitas komunikasi yang kamu inginkan dalam program?",
  expectNutritionist: "Apa lagi yang kamu harapkan dari Ahli Gizi Dietela?",
  expectProgram: "Apa yang kamu harapkan dari program yang kamu pilih?",
}

export default {
  ...page1,
  ...page2,
  ...page3,
  ...page4,
  ...page6,
  ...page7,
}
