
let sortingTables = {
    breakfast: 1,
    morning_snack: 2,
    lunch: 3,
    afternoon_snack: 4,
    dinner: 5,
    night_snack: 6,
}
export let sortMealCategory = (categoryA, categoryB) =>
  sortingTables[categoryA] - sortingTables[categoryB];